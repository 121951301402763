<script>
import axios from "axios";

import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import { required, email } from "vuelidate/lib/validators";
let user = JSON.parse(localStorage.getItem("user"));

/**
 * Change component
 */
export default {
  page: {
    title: "Thêm tài khoản nhân viên",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Sửa thông tin tài khoản",
      items: [
        {
          text: "Trang Chủ",
          href: "/",
        },
        {
          text: "Sửa thông tin tài khoản",
          active: true,
        },
      ],

      user: {
        username: null,
        email: null,
        password: null,
        admin: null,
        avatar: null
      },
      submitted: false,
      regError: null,
      tryingToEdit: false,
      isEditError: false,
      editSuccess: false,
      scriptSuccess: null,
    };
  },
  validations: {
    user: {
      username: {
        required,
      },
      email: {
        required,
        email,
      },
      avatar: {
        required,
      },
    },
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  methods: {
    // Try toedit the user in with the email, username
    // and password they provided.
    tryToEditIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        axios({
          url: process.env.VUE_APP_SERVER_URL + "/api/user/update",
          method: "post",
          headers: {
            authorization: user.token,
          },

          data: this.user,
        })
          .then(({ data }) => {
            this.tryingToEdit = false;
            this.isEditError = false;
            this.editSuccess = true;

            if (data && data.email && data._id == user._id) {
              data.token = user.token;
              localStorage.setItem("user", JSON.stringify(data));
            }

            this.$toasted.success("Cập nhập tài khoản thành công");
            return data;
          })
          .catch((error) => {
            this.tryingToEdit = false;
            this.regError =
              error && error.response && error.response.data
                ? error.response.data
                : "";
            this.$toasted.error(error.response.data);
            this.isEditError = true;
          });
      }
    },
  },

  created() {
    if (!this.$route.query.id) {
      this.$router.push("/");
    }
    let _id = this.$route.query.id;

    axios({
      url: process.env.VUE_APP_SERVER_URL + "/api/user/info",
      method: "post",
      headers: {
        authorization: user.token,
      },

      data: {
        _id: _id,
      },
    })
      .then(({ data }) => {
        if (data) {
          this.user = data;
          this.user.password = null;
          this.user.avatar = this.user.avatar ? this.user.avatar : null;
        } else {
          this.$toasted.error("Không tìm thấy thành viên phù hợp");
          this.$router.push("/");
        }
      })
      .catch((err) => {
        this.$toasted.error("Không tìm thấy thành viên phù hợp");
        this.$router.push("/");
      });
  },
};
</script>

<template>
  <Layout>
    <PageHeader :items="items" />
    <div class="row justify-content-center">
      <div class="col-md-12 col-lg-8 col-xl-8">
        <div class="card overflow-hidden">
          <div class="bg-soft bg-primary">
            <div class="row">
              <div class="col-7">
                <div class="text-primary p-4">
                  <h5 class="text-primary">Sửa thông tin tài khoản</h5>
                  <p>Bạn cần nhập đầy đủ thông tin theo biểu mẫu.</p>
                </div>
              </div>
              <div class="col-5 align-self-end">
                <img
                  src="@/assets/images/profile-img.png"
                  alt
                  class="img-fluid"
                />
              </div>
            </div>
          </div>

          <div class="card-body pt-0">
            <div>
              <router-link to="/">
                <div class="avatar-md profile-user-wid mb-4">
                  <span class="avatar-title rounded-circle bg-light">
                    <img
                      src="@/assets/images/logo.svg"
                      alt
                      class="rounded-circle"
                      height="34"
                    />
                  </span>
                </div>
              </router-link>
            </div>

            <b-alert
              v-if="editSuccess"
              v-model="editSuccess"
              class="mt-3"
              variant="success"
              dismissible
              >Sửa thông tin <b>{{ user.username }}</b> thành công
            </b-alert>

            <b-alert
              v-model="isEditError"
              class="mt-3"
              variant="danger"
              dismissible
              >{{ regError }}</b-alert
            >

            <b-form class="p-2" @submit.prevent="tryToEditIn">
              <b-form-group
                class="mb-3"
                id="email-username"
                label="Tên hiển thị"
                label-for="username"
              >
                <b-form-input
                  id="username"
                  v-model="user.username"
                  type="text"
                  placeholder="Henry"
                  :class="{
                    'is-invalid': submitted && $v.user.username.$error,
                  }"
                ></b-form-input>
                <div
                  v-if="submitted && !$v.user.username.required"
                  class="invalid-feedback"
                >
                  Tên tài khoản là được yêu cầu
                </div>
              </b-form-group>

              <b-form-group
                class="mb-3"
                id="account-email"
                label="Email đăng nhập"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="user.email"
                  type="email"
                  disabled=""
                  placeholder="user@domain.com"
                ></b-form-input>
              </b-form-group>

                 <b-form-group
                class="mb-3"
                id="account-email"
                label="Mật khẩu mới"
                label-for="email"
              >
              <b-form-input
                  id="password"
                  v-model="user.password"
                  type="password"
                  placeholder="*******"
                ></b-form-input>
              </b-form-group>

    

              <b-form-group
                class="mb-3"
                id="account-avatar"
                label="Avatar"
                label-for="Avatar"
              >
                <b-form-input
                  id="Avatar"
                  v-model="user.avatar"
                  type="text"
                  placeholder="Avatar tài khoản user"
                  :class="{
                    'is-invalid': submitted && $v.user.avatar.$error,
                  }"
                ></b-form-input>
                <div
                  v-if="submitted && !$v.user.avatar.required"
                  class="invalid-feedback"
                >
                  Ảnh đại diện cần có
                </div>
              </b-form-group>

              <b-form-group>
                <span>Khóa tài khoản</span>
                <div class="py-2">
                  <b-form-select
                    class="form-control"
                    v-model="user.band"
                    size="md"
                    :options="[{ value: true, text: 'Khóa tài khoản' }, { value: false, text: 'Mở tài khoản' }]"
                  ></b-form-select>
                </div>
              </b-form-group>

              <div class="mt-4 d-grid">
                <b-button type="submit" variant="primary" class="btn-block"
                  >Cập nhập tài khoản</b-button
                >
              </div>
            </b-form>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>